import React from "react";
import {Link, graphql} from "gatsby";
import Layout from "../components/layout";
import moment from "moment";



export default function Blog({data}) {
    const { edges: posts } = data.allFile

    return (
        <Layout>
            <div className="blog-posts grid gap-3 grid-cols-1 lg:grid-cols-3">
                {posts.map(({node : post})=>{
                    return (
                        <div className="blog-post-preview m-2 md:p-4 p-2">

                            {/*Banner image*/}
                            <img className="rounded-lg w-full" alt="article-scene"
                                src={post.childMarkdownRemark.frontmatter.image}
                            />

                            {/*Title*/}
                            <h1 className="font-semibold text-gray-900 leading-none text-xl mt-1 capitalize truncate">
                                <Link to={post.childMarkdownRemark.frontmatter.slug}>{post.childMarkdownRemark.frontmatter.title}</Link>
                            </h1>

                            {/* Description */}
                            <div className="max-w-full">
                                <p className="text-base font-medium tracking-wide text-gray-600 mt-1">{post.childMarkdownRemark.excerpt}</p>
                            </div>

                            {/* Author */}
                            <div className="flex items-center space-x-2 mt-10">
                                {/* Author's profile photo */}
                                <img
                                    className="w-10 h-10 object-cover object-center rounded-full"
                                    src="https://randomuser.me/api/portraits/men/54.jpg"
                                    alt="random user"
                                />
                                <div>
                                     {/*Author name */}
                                    <p className="text-gray-900 font-semibold">{post.childMarkdownRemark.frontmatter.author}</p>
                                    <p className="text-gray-500 font-semibold text-sm">
                                        { moment.utc(post.childMarkdownRemark.frontmatter.date, "YYYY-MM-DD").format("D MMM YYYY") } &middot; 6 min read
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <Link to="/">Go back to the homepage</Link>
        </Layout>
    )
}

export const pageQuery = graphql`{
  allFile(sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date}, filter: {sourceInstanceName: {eq: "articles"}}, limit: 1000) {
    edges {
      node {
        childMarkdownRemark {
            frontmatter {
              slug
              title
              date
              image
              author
            }
            excerpt(pruneLength: 50)
        }
      }
    }
  }
}
`